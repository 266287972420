import styled from "styled-components";

const PastRankTab = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #FFF;
`;

const PastRankBar = styled.div`
  position: sticky;
  top: 103px;
  background-color: #fff;
  z-index: 9;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
  height: 152px;

  &.scrolledPastRankTab {
    border-bottom: 1px solid #E6EAEB;
  }

  & > input {
    height: 48px;
    text-align: center;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.25px;
    border-radius: 8px;
    color: ${({theme}) => theme.colors.gray900};
    background-color: ${({theme}) => theme.colors.gray100};
    outline: none;
  }

  & > ul {
    display: flex;
    flex-direction: row;
    gap: 6px;
    height: 40px;
    justify-content: center;
    align-items: center;
    
    & > div {
      height: 100%;
    }
  }
`;

const PastRankButton = styled.li`
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  background-color: #FFF;
  border: 1px solid;
  border-color: ${({theme}) => theme.colors.gray200};
  color: ${({theme}) => theme.colors.gray700};
  cursor: pointer;
  border-radius: 20px;
  font-weight: 600;
  font-size: 17px;
  letter-spacing: -0.4px;

  &.active {
    background-color: ${({theme}) => theme.colors.gray800};
    color: #FFF;
  }
`;

const Style = {
    PastRankTab,PastRankBar,PastRankButton
}

export default Style;