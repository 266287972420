import React from "react";
import styled from 'styled-components';
import {NavLink} from "react-router-dom";
import { ReactComponent as VoteIcon} from "../../assets/images/icon/Icon_Vote.svg";
import { ReactComponent as RankingIcon} from "../../assets/images/icon/Icon_Ranking.svg";

const BottomNav = () => {
    return (
        <BottomNavWrapper>
            <ul>
                <BottomNavTab><NavLink to="/vote"><VoteIcon/><span>일일투표</span></NavLink></BottomNavTab>
                <BottomNavTab><NavLink to="/rank"><RankingIcon/><span>순위</span></NavLink></BottomNavTab>
            </ul>
        </BottomNavWrapper>
    )
}

export default BottomNav;

const BottomNavWrapper = styled.nav`
  position: fixed;
  display: flex;
  bottom: 0;
  max-width: 768px;
  min-width: 360px;
  width: 100%;
  z-index: 10;
  height: calc(56px + env(safe-area-inset-bottom));
  justify-content: space-around;
  padding: 0 16px env(safe-area-inset-bottom);
  background-color: #fff;
  border-top: 1px solid ${({theme}) => theme.colors.gray100};

  ul {
    width: 100%;
    display: flex;
  }
}
`;

const BottomNavTab = styled.li`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    color: ${({theme}) => theme.colors.gray700};

  & > a {
        display: flex;
        flex-direction: column;
        align-items: center;
      
      &.active {
        color: ${({theme}) => theme.colors.primary500};
      }

      &.active > svg path {
        fill: ${({theme}) => theme.colors.primary500};
      }
    }
`;