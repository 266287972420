import React from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";
import RankingImage from "./RankingImage";

const RankingItem = (props) => {
    if(props.propsLength === 'one'){
        const {list, year, month} = props;
        const top3 = list.rank === 1 || list.rank === 2 || list.rank === 3;
        let now = new Date();
        let todayDate = now.getDate();
        if(todayDate === 1){
          return (
            <RankItem>
                <Link to={`/rank/history/${list.id}`} state={{ props }}>
                    <RankItemInfo>
                        {top3 ? <RankingImage list={list}/> : (
                            list.rank === 0 ? <small>-</small> : <small>{list.rank}</small>
                        )}
                        <h6><img src={list.image} alt="rankingImage"/></h6>
                        <RankNameScore>
                            <h5>{list.name}</h5>
                            <p>{list.score}<span>점</span></p>
                        </RankNameScore>
                    </RankItemInfo>
                    <ListArrow width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M17.8964 12.2161C17.9104 12.1441 17.9114 12.0731 17.9114 12.0001C17.9114 11.9261 17.9104 11.8561 17.8964 11.7841C17.8824 11.7131 17.8564 11.6471 17.8294 11.5791C17.8004 11.5071 17.7704 11.4391 17.7274 11.3741C17.7104 11.3491 17.7064 11.3191 17.6884 11.2951C17.6574 11.2551 17.6134 11.2351 17.5784 11.2011C17.5444 11.1671 17.5244 11.1241 17.4864 11.0941L10.9284 5.9931C10.4274 5.6031 9.70441 5.6941 9.31441 6.1951C8.92441 6.6961 9.01441 7.4191 9.51641 7.8091L14.9064 12.0001L9.51541 16.1921C9.01441 16.5831 8.92341 17.3051 9.31341 17.8061C9.54041 18.0971 9.87941 18.2501 10.2224 18.2501C10.4694 18.2501 10.7174 18.1711 10.9274 18.0081L17.4854 12.9071C17.4874 12.9061 17.4874 12.9041 17.4894 12.9021C17.5614 12.8451 17.6284 12.7801 17.6874 12.7051C17.7064 12.6811 17.7104 12.6511 17.7264 12.6261C17.7694 12.5601 17.8004 12.4921 17.8284 12.4201C17.8564 12.3531 17.8834 12.2881 17.8964 12.2161Z"
                            fill="#ABB6BA"/>
                    </ListArrow>
                </Link>
            </RankItem>
        )} else {
          return (
            <RankItem>
                <Link to={`/rank/${list.id}`} state={{ props }}>
                    <RankItemInfo>
                        {top3 ? <RankingImage list={list}/> : (
                            list.rank === 0 ? <small>-</small> : <small>{list.rank}</small>
                        )}
                        <h6><img src={list.image} alt="rankingImage"/></h6>
                        <RankNameScore>
                            <h5>{list.name}</h5>
                            <p>{list.score}<span>점</span></p>
                        </RankNameScore>
                    </RankItemInfo>
                    <ListArrow width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M17.8964 12.2161C17.9104 12.1441 17.9114 12.0731 17.9114 12.0001C17.9114 11.9261 17.9104 11.8561 17.8964 11.7841C17.8824 11.7131 17.8564 11.6471 17.8294 11.5791C17.8004 11.5071 17.7704 11.4391 17.7274 11.3741C17.7104 11.3491 17.7064 11.3191 17.6884 11.2951C17.6574 11.2551 17.6134 11.2351 17.5784 11.2011C17.5444 11.1671 17.5244 11.1241 17.4864 11.0941L10.9284 5.9931C10.4274 5.6031 9.70441 5.6941 9.31441 6.1951C8.92441 6.6961 9.01441 7.4191 9.51641 7.8091L14.9064 12.0001L9.51541 16.1921C9.01441 16.5831 8.92341 17.3051 9.31341 17.8061C9.54041 18.0971 9.87941 18.2501 10.2224 18.2501C10.4694 18.2501 10.7174 18.1711 10.9274 18.0081L17.4854 12.9071C17.4874 12.9061 17.4874 12.9041 17.4894 12.9021C17.5614 12.8451 17.6284 12.7801 17.6874 12.7051C17.7064 12.6811 17.7104 12.6511 17.7264 12.6261C17.7694 12.5601 17.8004 12.4921 17.8284 12.4201C17.8564 12.3531 17.8834 12.2881 17.8964 12.2161Z"
                            fill="#ABB6BA"/>
                    </ListArrow>
                </Link>
            </RankItem>
          )
        }
    } else if(props.propsLength === 'three'){
        const {list, year, month} = props;
        const top3 = list.rank === 1 || list.rank === 2 || list.rank === 3;
        return (
            <RankItem>
                <Link to={`/rank/history/${list.id}`} state={{ props }}>
                    <RankItemInfo>
                        {top3 ? <RankingImage list={list}/> : (
                            list.rank === 0 ? <small>-</small> : <small>{list.rank}</small>
                        )}
                        <h6><img src={list.image} alt="rankingImage"/></h6>
                        <RankNameScore>
                            <h5>{list.name}</h5>
                            <p>{list.score}<span>점</span></p>
                        </RankNameScore>
                    </RankItemInfo>
                    <ListArrow width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M17.8964 12.2161C17.9104 12.1441 17.9114 12.0731 17.9114 12.0001C17.9114 11.9261 17.9104 11.8561 17.8964 11.7841C17.8824 11.7131 17.8564 11.6471 17.8294 11.5791C17.8004 11.5071 17.7704 11.4391 17.7274 11.3741C17.7104 11.3491 17.7064 11.3191 17.6884 11.2951C17.6574 11.2551 17.6134 11.2351 17.5784 11.2011C17.5444 11.1671 17.5244 11.1241 17.4864 11.0941L10.9284 5.9931C10.4274 5.6031 9.70441 5.6941 9.31441 6.1951C8.92441 6.6961 9.01441 7.4191 9.51641 7.8091L14.9064 12.0001L9.51541 16.1921C9.01441 16.5831 8.92341 17.3051 9.31341 17.8061C9.54041 18.0971 9.87941 18.2501 10.2224 18.2501C10.4694 18.2501 10.7174 18.1711 10.9274 18.0081L17.4854 12.9071C17.4874 12.9061 17.4874 12.9041 17.4894 12.9021C17.5614 12.8451 17.6284 12.7801 17.6874 12.7051C17.7064 12.6811 17.7104 12.6511 17.7264 12.6261C17.7694 12.5601 17.8004 12.4921 17.8284 12.4201C17.8564 12.3531 17.8834 12.2881 17.8964 12.2161Z"
                            fill="#ABB6BA"/>
                    </ListArrow>
                </Link>
            </RankItem>
        )
    }else {
        const {list, year, month, day} = props;
        const top3 = list.rank === 1 || list.rank === 2 || list.rank === 3;
        return (
            <RankItem>
                <RankItemInfo>
                    {list.rank === 1 ? <small className={"dailyRankingFirstRank"}>1</small>
                        : (
                            list.rank === 2 || list.rank === 3 ? <small className={"dailyRankingTwoThreeRank"}>{list.rank}</small> : <small>-</small>
                        )}
                    <h6><img src={list.image} alt="rankingImage"/></h6>
                    <RankNameScore>
                        <h5>{list.name}</h5>
                        <p>{list.score}<span>점</span></p>
                    </RankNameScore>
                </RankItemInfo>
            </RankItem>
        )
    }
}

export default RankingItem;

const RankItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 72px;
  padding: 8px 26px;
  cursor: pointer;

  & > a {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  h6 {
    width: 48px;
    height: 48px;
    display: inline-block;
    border: 1px solid;
    border-color: ${({theme}) => theme.colors.gray100};
    border-radius: 24px;
    overflow: hidden;
  }
`;

const RankItemInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  height: inherit;

  & > small {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.25px;
    color: ${({theme}) => theme.colors.gray700};

    &.dailyRankingFirstRank {
      font-weight: 600;
      font-size: 22px;
      line-height: 26px;
      text-align: center;
      letter-spacing: -0.3px;
      color: ${({theme}) => theme.colors.primary500};
    }
    
    &.dailyRankingTwoThreeRank {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      letter-spacing: -0.15px;
      color: ${({theme}) => theme.colors.primary800};
    }
  }

  & > span {
    width: 48px;
    height: 48px;
  }
`;

const RankNameScore = styled.div`
  & > h5 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.15px;
    color: ${({theme}) => theme.colors.gray900}
  }

  & > p {
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.25px;
    color: ${({theme}) => theme.colors.gray700};
    
    & > span {
      padding-left: 2px;
    }
  }
}
`;

const ListArrow = styled.svg`
  width: 16px;
  height: 16px;

  & > path {
    fill: ${({theme}) => theme.colors.gray600};
  }
`;
