import React from "react";
import styled from "styled-components";

const Styled_GoToVoteInfo = styled.p`
  margin-bottom: 6px;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.25px;
  color: ${({theme}) => theme.colors.gray700};
  
  & > span {
    font-weight: 600;
    color: ${({theme}) => theme.colors.primary500};
  }
`;

const GoToVoteInfo = ({rankingCategory}) => {
    return (
        rankingCategory === "순위" ? (
            <Styled_GoToVoteInfo>{rankingCategory}는 <span>매월 2일부터 공개</span>됩니다.</Styled_GoToVoteInfo>
        ) : (
            <Styled_GoToVoteInfo>{rankingCategory}는 이번 투표를 반영해 <br/><span>4월 1일에 공개</span>됩니다.</Styled_GoToVoteInfo>
        )

    )
}

export default GoToVoteInfo;