import React from "react";
import styled from "styled-components";
// import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <FooterWrapper>
            <FooterUl>
                <li><a href="https://terms.fanmaum.com/agreement.html" target="_blank" rel="noreferrer">이용약관</a></li>
                <li><a href="https://privacy.fanmaum-dev.com/" target="_blank" rel="noreferrer">개인정보처리방침</a></li>
            </FooterUl>
            <FooterContents>
                <div>
                    <span>상호명 : (주)팬마음</span>  <span>대표자 : 이기현</span>
                    <p>서울시 강남구 역삼로3길 17-6, 10층</p>
                    <p>사업자 번호 : 695-86-00419</p>
                    <p>통신판매업 신고 번호 : 제2016-서울강남-01323호</p>
                    <p>contact@fanmaum.com</p>
                </div>
                <address>Copyright © 팬마음. All Rights Reserved.</address>
            </FooterContents>
        </FooterWrapper>
    )
}

export default Footer;

const FooterWrapper = styled.footer`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 768px;
    height: 244px;
    background-color: ${({theme})=> theme.colors.gray50};
    text-align: center;
    padding: 32px 0;
`;

const FooterUl = styled.ul`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 24px;

    & > li > a {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: -0.25px;
        color: ${({theme})=> theme.colors.gray800};
    }
`;

const FooterContents = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.25px;
    color: ${({theme})=> theme.colors.gray650};

    & > address {
        font-style: normal;
    }
`;