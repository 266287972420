import React, {useState} from 'react';
import styled from "styled-components";

import DetailPageHeader from "../components/DetailPageHeader";
import PastMonthlyRanking from "../components/pastRank/PastMonthlyRanking";
import PastDailyRanking from "../components/pastRank/PastDailyRanking";
import Tab from "../components/Tab/Tab";

const PastRanking = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const tabClickHandler = index => setActiveIndex(index);
    let current = new Date();
    let oneDayAgo = new Date(current.setDate(current.getDate() - 1));

    let tabContArr = [
        {
            label: (<TabButton className={activeIndex === 0 ? "is-active" : ""}
                               onClick={() => tabClickHandler(0)}>월간 순위</TabButton>),
            content: <PastMonthlyRanking/>
        },
        {
            label: (<TabButton className={activeIndex === 1 ? "is-active" : ""}
                               onClick={() => tabClickHandler(1)}>일일 순위</TabButton>),
            content: <PastDailyRanking date={oneDayAgo}/>
        }
    ];

    return (
        <div style={{position: "relative"}}>
            <DetailPageHeader title="지난 순위"/>
            <Tab tabContArr={tabContArr} activeIndex={activeIndex}/>
        </div>
    )
}
export default PastRanking;

const TabButton = styled.li`
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  font-weight: 500;
  font-size: 17px;
  letter-spacing: -0.25px;
  cursor: pointer;
  color: ${({theme}) => theme.colors.gray700};

  &.is-active {
    color: ${({theme}) => theme.colors.primary900};
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.4px;
  }
`;