import React from "react";
import styled from "styled-components";
import {Player, Controls} from "@lottiefiles/react-lottie-player";
import RankingArrow from "../../assets/json/Ranking_Arrow.json";

const BtnChip = ({title}) => {
    return (
        <Styled_BtnChip className="chipButton">
            {title}
            <Player autoplay
                    speed={1}
                    loop
                    src={RankingArrow}
                    style={{ height: '40px', width: '40px' }}>
                <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
            </Player>
        </Styled_BtnChip>
    )
}

export default BtnChip;

const Styled_BtnChip = styled.button`
  position: fixed;
  bottom: 76px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 16px;
  background-color: ${({theme})=> theme.colors.gray900};
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  color: #fff;

  &.hideChipButton {
    display: none;
  }

  & > img {
    width: 40px;
    height: 40px;
  }
`;