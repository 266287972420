import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  html {
    width: 100%;
    height: 100vh;
  }
  #root {
    width: 100%;
    height: 100vh;
  }
  body {
    width: 100%;
    height: 100vh;
    //height: 100%;
    max-width: 768px;
    min-width: 360px;
    margin: 0 auto;
    padding: 0;
    overflow-y: auto;
    font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    -webkit-text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    background-color: ${({theme}) => theme.colors.gray50};
  }
`;

export default GlobalStyle;