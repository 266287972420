import axios from "axios";
import client from "./axios";
import {getPastYear, getPastMonth, isCheckLessThanTen} from "../utils/DateUtil";

export const getMonthlyRank = async () => {
    let url = `/rank/monthly`;
    const response = await axios.get(url,{
        headers: {
            'Content-Type': 'application/json',
            'bear_token': process.env.REACT_APP_BEAR_TOKEN
        },
        withCredentials: true,
    })
    return response;
}

export const getMonthlyHistory = async ({id, year, month}) => {
    let pastYear = getPastYear();
    let pastMonth = getPastMonth();
    console.log(pastYear, pastMonth);
    console.log(year, month);
    let url = `/rank/monthly/detail?star_id=${id}&year=${year}&month=${month}`;
    (year === undefined && month === undefined) && (url = `/rank/monthly/detail?star_id=${id}`);
    (year === 0 && month === 0) && (url = `/rank/monthly/detail?star_id=${id}&year=${pastYear}&month=${pastMonth}`);

    const response = await axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
            'bear_token': process.env.REACT_APP_BEAR_TOKEN
        },
        withCredentials: true,
    });
    return response;
};

export const getDailyHistory = async ({year, month, day}) => {
    let url = `/rank/daily?year=${year}&month=${month}&day=${isCheckLessThanTen(day)}`;
    const response = await axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
            'bear_token': process.env.REACT_APP_BEAR_TOKEN
        },
        withCredentials: true,
    });
    return response;
};
