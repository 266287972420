import React from "react";
import styled from "styled-components";
import {Player, Controls} from "@lottiefiles/react-lottie-player";
import Spinner from "../assets/json/Loading_Spinner.json";

const Loading = () => {
    return (
        <Styled_Loading>
            <Player autoplay
                    speed={1}
                    loop
                    src={Spinner}
                    style={{ height: '40px', width: '40px' }}>
                <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
            </Player>
        </Styled_Loading>
    )
}

export default Loading;

const Styled_Loading = styled.button`
    display: flex;
    width: 128px;
    height: 128px;
    margin: 0 auto;

    & > div > div {
        width: 128px !important;
        height: 128px !important;
    }
`;