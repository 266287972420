import React, {useEffect, useState} from "react";
import TopRanks from "../TopRanks";
import TopRankList from "./TopRankList";
import RankingList from "./RankingList";
import {getMonthlyRank} from "../../api/Ranking";
import {getPastMonthlyRank} from "../../api/PastRank";
import Loading from "../Loading";

const RankTabList = ({gender}) => {
    const [rankData, setRankData] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        let now = new Date();
        let todayDate = now.getDate();
        if(todayDate === 1){
            let year = now.getFullYear();
            let month = now.getMonth();
            getPastMonthlyRank({year,month}).then(response => {
                console.log(response.data.data);
                setIsLoaded(true);
                setRankData(response.data.data);
            }).catch(error => {
                setIsLoaded(true);
                console.log(error);
            });
        }else {
            getMonthlyRank().then(response => {
                console.log(response.data.data);
                setIsLoaded(true);
                setRankData(response.data.data);
            }).catch(error => {
                setIsLoaded(true);
                console.log(error);
            });
        }
    }, []);

    if(!isLoaded){
        return <Loading/>
    }else {
        return (
            rankData[gender]?.filter((element) => element.rank < 4 && element.rank > 0).length === 3 ? (
                <>
                    <TopRanks rankData={rankData} gender={gender}/>
                    <TopRankList rankData={rankData} gender={gender}/>
                </>
            ) : <RankingList rankData={rankData} gender={gender}/>
        )
    }
}

export default RankTabList;