import styled from "styled-components";
import RankingItem from "./RankingItem";

const RankingList = ({rankData, gender}) => {
    const genderFilteredData = rankData[gender];
    return (
        <RankingListWrapper>
            {genderFilteredData.map(( list ) => (
                <RankingItem list={list} key={list.id} propsLength={"one"}/>
            ))}
        </RankingListWrapper>
    )
}

export default RankingList;

const RankingListWrapper = styled.ul`
  width: 100%;
  margin: 16px 0 0 0;
  background-color: #fff;
`;