import React from "react";
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import IconBack from "../assets/images/icon/Icon_Back.svg";

const DetailPageHeader = ({title}) => {
    const navigate = useNavigate();
    return (
        <DetailPageHeaderWrapper>
            <button onClick={()=> navigate(-1)}><img src={IconBack} alt="뒤로가기"/></button>
            <h1>{title}</h1>
        </DetailPageHeaderWrapper>
    )
}

export default DetailPageHeader;

const DetailPageHeaderWrapper = styled.header`
  position: sticky;
  width: 100%;
  max-width: 768px;
  top: 0;
  display: flex;
  flex-direction: column;
  z-index: 9;
  height: 56px; 
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 16px 12px;
  
  & > button {
    position: absolute;
    left: 12px;
  }
  
  & > h1 {
    font-weight: 500;
    font-size: 17px;
    letter-spacing: -0.25px;
    color: ${({theme})=> theme.colors.gray800};
  }
`;