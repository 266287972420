import React, {useState, useRef, useEffect} from "react";
import styled from "styled-components";

const TabButtonWrap = styled.ul`
  position: sticky;
  top: 56px;
  z-index: 9;
  display: flex;
  height: 48px;
  background-color: #fff;
  border-bottom: 1px solid ${({theme}) => theme.colors.gray200};

  & > div {
    flex: 1;
  }
`;

const Tab = ({tabContArr, activeIndex}) => {
    const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
    const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
    const tabsRef = useRef([]);

    useEffect(() => {
        function setTabPosition() {
            const currentTab = tabsRef.current[activeIndex];
            setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
            setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
        }

        setTabPosition();
        window.addEventListener("resize", setTabPosition);

        return () => window.removeEventListener("resize", setTabPosition);
    }, [activeIndex]);

    return (
        <div style={{height: "calc(100% - 56px)"}}>
            <TabButtonWrap>
                {tabContArr.map((section, index) => {
                    return (
                        <div key={index}
                             ref={(el) => (tabsRef.current[index] = el)}>
                            {section.label}
                        </div>
                    )
                })}
                <span className="absolute bottom-0 block h-1 transition-all duration-300"
                      style={{left: tabUnderlineLeft, width: tabUnderlineWidth, backgroundColor: '#F7ACAE'}}/>
            </TabButtonWrap>
            <div style={{height: "calc(100% - 48px)"}}>
                {tabContArr[activeIndex].content}
            </div>
        </div>
    )
}

export default Tab;