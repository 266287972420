import React from "react";
import Header from "../layout/Header";
import BottomNav from "../layout/BottomNav";
import styled from "styled-components";

const LayoutWrapper = styled.div`
  position: relative;
  max-width: 768px;
  min-width: 360px;
  margin: 0 auto;
  min-height: 100vh;
  background-color: #F9FAFA;
`;

const Layout = ({children}) => {
    return (
        <LayoutWrapper>
            <Header/>
            {children}
            <BottomNav/>
        </LayoutWrapper>
    )
}

export default Layout;