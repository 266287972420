import React from "react";
import GlobalStyle from "./styles/GlobalStyle";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {ThemeProvider} from 'styled-components';
import theme from "./styles/theme";

import Vote from "./page/Vote";
import Rank from "./page/Rank";
import Benefits from "./page/Benefits";
import PastRanking from "./page/PastRanking";
import RankDetail from "./page/RankDetail";

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={<h1>home</h1>}></Route>
                    <Route path="/vote" element={<Vote/>}></Route>
                    <Route path="/rank" element={<Rank/>}></Route>
                    <Route path="/rank/:historyId" element={<RankDetail/>}></Route>
                    <Route path="/rank/history/:historyId" element={<RankDetail/>}></Route>
                    <Route path="/rank/benefits" element={<Benefits/>}></Route>
                    <Route path="/rank/pastRanking" element={<PastRanking/>}></Route>
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    );
}
export default App;