import React from "react";
import {useNavigate} from "react-router-dom";
import {ReactComponent as VoteIcon} from "../../assets/images/icon/Icon_Vote.svg";
import styled from "styled-components";

const BtnOutlineLSecondary = ({title}) => {
    const navigation = useNavigate();

    return (
        <Styled_BtnOutlineLSecondary onClick={() => navigation("/vote")}>
            <VoteIcon/>
            {title}
        </Styled_BtnOutlineLSecondary>
    )
}

export default BtnOutlineLSecondary;

const Styled_BtnOutlineLSecondary = styled.button`
  display: flex;
  flex-direction: row;
  gap: 6px;
  background-color: ${({theme}) => theme.colors.secondary50};
  border: 1px solid;
  padding: 14px 24px;
  border-color: ${({theme}) => theme.colors.secondary300};
  border-radius: 14px;
  color: ${({theme}) => theme.colors.secondary800};

  svg path {
    fill: ${({theme}) => theme.colors.secondary500}
  }
}`;