const colors = {
    gray900: "#22282A",
    gray800: "#394346",
    gray750: "#475357",
    gray700: "#5C6B70",
    gray650: "#728388",
    gray600: "#819298",
    gray500: "#ABB6BA",
    gray400: "#BDC6C9",
    gray300: "#CFD5D8",
    gray200: "#E6EAEB",
    gray100: "#F2F4F5",
    gray50: "#F9FAFA",

    primary900: "#741538",
    primary800: "#8C2341",
    primary700: "#AE374F",
    primary600: "#D0515F",
    primary500: "#F26F72",
    primary400: "#F48D8F",
    primary300: "#F7ACAE",
    primary200: "#FACBCC",
    primary100: "#FDEAEB",
    primary50: "#FFF5F5",
    primary30: "#FFFAFA",

    secondary900: "#201977",
    secondary800: "#332A90",
    secondary700: "#4E43B3",
    secondary600: "#6E61D7",
    secondary500: "#9485FA",
    secondary400: "#AB9FFB",
    secondary300: "#C2B9FC",
    secondary200: "#D9D3FD",
    secondary100: "#F0EEFE",
    secondary50: "#F7F6FF",

    semanticPositive500: "#2DC4B3",
    semanticPositive300: "#81E2D7",
    semanticNegative500: "#F76C1B",
    semanticNegative300: "#FFAC7C"
}

const theme = {
    colors
}

export default theme;