import React, {useEffect, useRef, useState} from "react";
import Style from "./Style_PastRanking";

import GoToVote from "../rank/goToVote/GoToVote";
import PastDailyTabList from "../pastRank/PastDailyTabList";

// datepicker
import BtnFilledMPrimary from "../button/BtnFilledMPrimary";
import DatePicker from 'react-datepicker';
import ko from "date-fns/locale/ko";
import 'react-datepicker/dist/react-datepicker.css';
import CustomInput from "../input/CustomInput";

const PastDailyRanking = (props) => {
    const [menu, setMenu] = useState(0);
    const [year, setYear] = useState(0);
    const [month, setMonth] = useState(0);
    const [day, setDay] = useState(0);

    const [startDate, setStartDate] = useState(props.date);
    const calendar = useRef(null);
    const tabsRef = useRef([]);

    const [scrollPosition, setScrollPosition] = useState(0);
    const updateScroll = () => {
        setScrollPosition(window.scrollY);
    }
    useEffect(()=> {
        window.addEventListener('scroll', updateScroll);
        return () => window.removeEventListener("scroll", updateScroll);
    },[]);

    const tabClickHandler = (menu) => {
        let date = new Date(startDate);
        let year = date.getFullYear();
        let month = date.getMonth()+1;
        let day = date.getDate();
        setMenu(menu);
        setYear(year);
        setMonth(month);
        setDay(day);
    };

    let tabContArr = [
        {
            label: (<Style.PastRankButton className={menu === 0 ? "active" : ""}
                                          onClick={() => tabClickHandler(0)}>남자스타</Style.PastRankButton>),
            content: <PastDailyTabList gender="men" menu="0" year={year} month={month} day={day}/>
        },
        {
            label: (<Style.PastRankButton className={menu === 1 ? "active" : ""}
                                          onClick={() => tabClickHandler(1)}>여자스타</Style.PastRankButton>),
            content: <PastDailyTabList gender="women" menu="1" year={year} month={month} day={day}/>
        }
    ];

    const handleClickDatePickerButton = (menu) => {
        let date = new Date(startDate);
        let year = date.getFullYear();
        let month = date.getMonth()+1;
        let day = date.getDate();
        setYear(year);
        setMonth(month);
        setDay(day);
        calendar.current.setOpen(false);
    }

    console.log(`year: ${year}, month: ${month}, day: ${day}`);
    let today = new Date();
    let sel_day = -1;
    today.setDate(today.getDate() + sel_day );
    let todayYear = today.getFullYear();
    let todayMonth = ('0' + (today.getMonth() +  1 )).slice(-2);
    let lastDay = ('0' + (today.getDate())).slice(-2);

    return (
        <>
            <Style.PastRankTab>
                <Style.PastRankBar className={scrollPosition < 30 ? "" : "scrolledPastRankTab"}>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="yyyy.MM.dd"
                        locale={ko}
                        minDate={new Date('2022-10-01')}
                        maxDate={new Date(`${todayYear}-${todayMonth}-${lastDay}`)}
                        ref={calendar}
                        popperPlacement="auto"
                        yearItemNumber={1}
                        shouldCloseOnSelect={false}
                        customInput={<CustomInput sortType={"daily"}/>}
                    >
                        <div className="button-container">
                            <BtnFilledMPrimary className={"btn_ctrl btn_ctrl-confirm"}
                                           onClick={handleClickDatePickerButton}
                                           title={"적용"}/>
                        </div>
                    </DatePicker>
                    <ul>
                        {tabContArr.map((section, index) => {
                            return (
                                <div key={index}
                                     ref={(el) => (tabsRef.current[index] = el)}>
                                    {section.label}
                                </div>
                            )
                        })}
                    </ul>
                </Style.PastRankBar>
                <div>
                    {tabContArr[menu].content}
                </div>
            </Style.PastRankTab>
            {/*<GoToVote rankingCategory="일일 순위" title="투표 하러가기"/>*/}
        </>
    )
}

export default PastDailyRanking;