import React, {useState} from "react";
import styled from "styled-components";

const menuList = {
    0: "우승혜택1",
    1: "우승혜택2",
    2: "우승혜택3",
};

const BenefitsTab = () => {
    const [menu, setMenu] = useState(0);

    return (
        <StyledBenefitsTab>
            <BenefitsTabBar>
                <ul>
                    <BenefitsTabButton className={`${menu === 0 ? 'active' : ''}`} onClick={() => setMenu(0)}>
                        1위
                    </BenefitsTabButton>
                    <BenefitsTabButton className={`${menu === 1 ? 'active' : ''}`} onClick={() => setMenu(1)}>
                        2위
                    </BenefitsTabButton>
                    <BenefitsTabButton className={`${menu === 2 ? 'active' : ''}`} onClick={() => setMenu(2)}>
                        3위
                    </BenefitsTabButton>
                </ul>
            </BenefitsTabBar>
            <BenefitscontentArea>
                {menuList[menu]}
            </BenefitscontentArea>
        </StyledBenefitsTab>
    )
}

export default BenefitsTab;

const StyledBenefitsTab = styled.div`
  height: calc(100% - 56px);
`;

const BenefitsTabBar = styled.div`
  background-color: #FFF;
  height: 64px;
  
  & > ul {
    display: flex;
    flex-direction: row;
    gap: 6px;
    height: 100%;
    align-items: center;
    padding: 8px 0 16px 16px;
  }
`;

const BenefitsTabButton = styled.li`
  padding: 10px 20px;
  background-color: #FFF;
  border: 1px solid;
  border-color: ${({theme}) => theme.colors.gray200};
  color: ${({theme}) => theme.colors.gray700};
  cursor: pointer;
  border-radius: 20px;
  font-weight: 600;
  font-size: 17px;
  letter-spacing: -0.4px;

  &.active {
    background-color: ${({theme}) => theme.colors.gray750};
    color: #FFF;
  }
`;

const BenefitscontentArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 64px);
  background-color: rgba(247, 108, 27, 0.2);
  color: #F76C1B;
`;