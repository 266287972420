import React from "react";
import styled from "styled-components";
import RankingItem from "./RankingItem";

const TopRankList = ({rankData, gender}) => {
    const topRanksList = rankData[gender]?.filter((element) => element.rank > 3 || element.rank === 0);

    return (
        <RankingListWrapper>
            {topRanksList.map(( list ) => (
                <RankingItem list={list} key={list.id} propsLength={"one"}/>
            ))}
        </RankingListWrapper>
    )
}

export default TopRankList;

const RankingListWrapper = styled.ul`
  margin: 16px 0;
`;