import React from "react";
import styled from "styled-components";

const BtnFilledMPrimary = ({onClick, className, title}) => {
    return (
        <Styled_BtnFilledMPrimary onClick={onClick} className={className}>
            {title}
        </Styled_BtnFilledMPrimary>
    )
}

export default BtnFilledMPrimary;

const Styled_BtnFilledMPrimary = styled.button`
  width: calc(100% - 48px);
  box-shadow: 4px 4px 8px rgba(167, 17, 68, 0.2);
  border-radius: 28px;
  background-color: ${({theme}) => theme.colors.primary500};
  padding: 10px 0;
  margin: 16px 24px 24px 24px;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.4px;
  color: #FFF;
`;