import React from "react";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Pagination, Navigation} from "swiper";

import 'swiper/css';

const NavWrapper = styled.nav`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 120px;
  background: linear-gradient(100.73deg, #4E43B3 3.31%, #6E61D7 37.59%, #B1A0F6 67.74%, #F8ADAF 95.6%);
  color: #fff;
  text-align: center;
`;

// slider
const TopNav = () => {
    const navigation = useNavigate();
    return (
        <NavWrapper>
            <Swiper
                pagination={{
                    type: "fraction",
                    clickable: true,
                }}
                loop={true}
                navigation={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination, Navigation]}
                spaceBetween={50}
                slidesPerView={1}
                onSwiper={(swiper) => swiper}>
                <SwiperSlide onClick={()=> navigation("/rank/benefits")}>탭 시 1/2/3위 혜택페이지 노출</SwiperSlide>
                <SwiperSlide onClick={()=> navigation("/rank/benefits")}>이건 두번째 탭</SwiperSlide>
            </Swiper>
        </NavWrapper>
    )
}

export default TopNav;