import React, {useEffect, useState} from "react";
import PastDailyRankingList from "../pastRank/PastDailyRankingList";
import {getDailyHistory} from "../../api/Ranking";
import Loading from "../Loading";

const PastDailyTabList = ({gender, menu, year, month, day}) => {
    const [rankData, setRankData] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        getDailyHistory({year, month, day}).then((response) => {
            console.log(response);
            setIsLoaded(true);
            setRankData(response.data.data);
        })
        .catch(error => {
            setIsLoaded(true);
            console.log(error);
        });
    }, [year, month, day]);

    if(!isLoaded){
        return <Loading/>
    }else {
        return <PastDailyRankingList rankData={rankData} gender={gender} year={year} month={month} day={day}/>
    }
}

export default PastDailyTabList;