import React, {useEffect, useState} from "react";
import PastRankingList from "../pastRank/PastRankingList";
import {getPastMonthlyRank} from "../../api/PastRank";
import Loading from "../Loading";

const PastRankTabList = ({gender, menu, year, month}) => {
    const [rankData, setRankData] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        getPastMonthlyRank({year, month}).then((response) => {
            console.log(response);
            setIsLoaded(true);
            setRankData(response.data.data);
        })
        .catch(error => {
            setIsLoaded(true);
            console.log(error);
        });
    }, [year, month]);

    if(!isLoaded){
        return <Loading />
    }else {
        return <PastRankingList rankData={rankData} gender={gender} year={year} month={month}/>
    }
}

export default PastRankTabList;