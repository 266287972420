import React, {useState, useRef, useEffect} from "react";
import RankTabList from "../rank/RankTabList";
import styled from "styled-components";

const RankTab = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
    const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
    const tabsRef = useRef([]);

    const [scrollPosition, setScrollPosition] = useState(0);
    const updateScroll = () => {
        setScrollPosition(window.scrollY);
    }
    useEffect(()=> {
        window.addEventListener('scroll', updateScroll);
        return () => window.removeEventListener("scroll", updateScroll);
    },[]);


    const tabClickHandler = index => setActiveIndex(index);
    useEffect(() => {
        const setTabPosition = () => {
            const currentTab = tabsRef.current[activeIndex];
            setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
            setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
        }
        setTabPosition();
        window.addEventListener("resize", setTabPosition);
        return () => window.removeEventListener("resize", setTabPosition);
    }, [activeIndex]);

    const tabContArr = [
        {
            label: (<TabButton className={activeIndex === 0 ? "is-active" : ""}
                               onClick={() => tabClickHandler(0)}>남자스타</TabButton>),
            content: <RankTabList gender="men"/>
        },
        {
            label: (<TabButton className={activeIndex === 1 ? "is-active" : ""}
                               onClick={() => tabClickHandler(1)}>여자스타</TabButton>),
            content: <RankTabList gender="women"/>
        }
    ];

    return (
        <div>
            <TabButtonWrap>
                {tabContArr.map((section, index) => {
                    return <div key={index}
                                ref={(el) => (tabsRef.current[index] = el)}>
                                {section.label}
                            </div>
                })}
                <span className="absolute bottom-0 block h-1 transition-all duration-300"
                      style={{left: tabUnderlineLeft, width: tabUnderlineWidth, backgroundColor: '#F7ACAE'}}/>
            </TabButtonWrap>
            <div>
                {tabContArr[activeIndex].content}
            </div>
        </div>
    )
}
export default RankTab;

const TabButtonWrap = styled.ul`
  position: relative;
  display: flex;
  height: 48px;
  border-bottom: 1px solid ${({theme}) => theme.colors.gray200};
  
  & > div {
    flex: 1;
  }
`;

const TabButton = styled.li`
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  font-weight: 500;
  font-size: 17px;
  letter-spacing: -0.25px;
  cursor: pointer;
  color: ${({theme}) => theme.colors.gray700};

  &.is-active {
    color: ${({theme}) => theme.colors.primary900};
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.4px;
  }
`;