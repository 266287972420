import React from "react";
import Layout from "../components/layout/Layout";
import RankContainer from "../components/rank/RankContainer";

const Rank = () => {
    return (
        <Layout>
            <RankContainer/>
        </Layout>
    )
}

export default Rank;
