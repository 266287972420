import styled from "styled-components";
import RankingItem from "../rank/RankingItem";

const PastDailyRankingList = (props) => {
    const {rankData, gender, year, month, day} = props;
    const genderFilteredData = rankData[gender];
    return (
        <RankingListWrapper>
            {genderFilteredData.map(( list ) => (
                <RankingItem list={list} key={list.name} year={year} month={month} day={day}/>
            ))}
        </RankingListWrapper>
    )
}

export default PastDailyRankingList;

const RankingListWrapper = styled.ul`
  width: 100%;
  margin: 16px 0;
  background-color: #fff;
  
  & > li {
    cursor: default;
  }
`;