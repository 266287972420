import React from "react";
import Layout from "../components/layout/Layout";

const Vote = () => {
    return (
        <Layout>
            Vote
        </Layout>
    )
}

export default Vote;
