import React from 'react';
import noHistoryIcon from "../../../assets/images/icon/Icon_NoHistory.svg";
import styled from "styled-components";
import BtnOutlineLSecondary from "../../button/BtnOutlineLSecondary";
import GoToVoteInfo from "../goToVote/GoToVoteInfo";

const GoToVote = ({rankingCategory,title}) => {
    return (
        <GoToVoteWrapper>
            <img src={noHistoryIcon} alt="noHistory"/>
            <h4>팬마음 첫 투표 진행중!</h4>
            <GoToVoteInfo rankingCategory={rankingCategory}/>
            <p className="GoToVoteMessage">지금 바로 내 스타에게 투표해보세요!</p>
            <BtnOutlineLSecondary title={title} />
        </GoToVoteWrapper>
    )
}

export default GoToVote;

const GoToVoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;    
  min-height: calc(100vh - 320px);
  justify-content: center;
  align-items: center;
  background-color: #fff;
  
  & > img {
    width: 88px;
    height: 88px;
    margin-bottom: 8px;
  }

  & > h4 {
    color: ${({theme}) => theme.colors.gray800};
    margin-bottom: 24px;

    font-weight: 600;
    font-size: 20px;
    text-align: center;
    letter-spacing: -0.15px;
  }

  & > p.GoToVoteMessage {
    color: ${({theme}) => theme.colors.gray700};
    margin-bottom: 32px;

    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.25px;
  }
`;