import axios from "axios";
import client from "./axios";

export const getPastMonthlyRank = async ({year, month}) => {
    let date = new Date();
    let sel_month = -1;
    date.setMonth(date.getMonth() + sel_month );
    let pastYear    = date.getFullYear();
    let pastMonth   = ('0' + (date.getMonth() +  1 )).slice(-2);
    console.log(pastYear, pastMonth);

    let url = `/rank/monthly?year=${year}&month=${month}`;
    (year === 0 && month === 0) && (url = `/rank/monthly?year=${pastYear}&month=${pastMonth}`);

    console.log(url);
    const response = await axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
            'bear_token': process.env.REACT_APP_BEAR_TOKEN
        },
        withCredentials: true,
    });
    return response;
};

export const getPastDailyRank = async ({year, month, day}) => {
    let url = `/rank/daily?year=${year}&month=${month}&day=${day}`;
}
