import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {getMonthlyHistory} from "../../api/Ranking"
import {getPastYear, getPastMonth} from "../../utils/DateUtil";
import Loading from "../Loading";

const RankingHistoryList = ({data}) => {
    console.log(data);
    let pastYear = getPastYear();
    let pastMonth = getPastMonth();
    let todayDay = new Date().getDate();
    console.log(pastYear, pastMonth, todayDay);
    let id = data.list?.id;
    let year = data?.year;
    let month = data?.month;
    let now = new Date();
    let thisYear = now.getFullYear();
    let thisMonth = now.getMonth() + 1;
    const [historyDtos, setHistoryDtos] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
      if(todayDay === 1){
        let year = 0;
        let month = 0;
        getMonthlyHistory({id, year, month}).then((response) => {
          console.log(response.data.data);
          setIsLoaded(true);
          setHistoryDtos(response.data.data);
      })
      }else {
        getMonthlyHistory({id, year, month}).then((response) => {
          console.log(response.data.data);
          setIsLoaded(true);
          setHistoryDtos(response.data.data);
      })
      }
    }, [todayDay, id, year, month]);

    console.log(`pastyear: ${pastYear}, pastMonth: ${pastMonth}`);
    console.log(`thisyear: ${thisYear}, thismonth: ${thisMonth}`);
    console.log(`year: ${year}, month: ${month}`);
    console.log(`todayday: ${todayDay}`);

    return (
        <>
            <HistoryTitleWrap>
                {
                  //현재순위 페이지에서 가수 클릭 시
                  (year === undefined) ? 
                    (todayDay === 1) ?
                    (<h3>{pastYear}년 {pastMonth}월</h3>) : 
                    (<h3>{thisYear}년 {thisMonth}월</h3> ): 
                    (
                      (year === 0 && month === 0) ? (<h3>{pastYear}년 {pastMonth}월</h3>) : (<h3>{year}년 {month}월</h3>)
                  )
                }
                <h6>한 달 동안의 순위내역을 확인할 수 있어요.</h6>
            </HistoryTitleWrap>
            <HistoryTable>
                <HistoryThead>
                    <tr>
                        <th>일자</th>
                        <th>투표수</th>
                        <th>일일점수</th>
                        <th>순위</th>
                    </tr>
                </HistoryThead>
                {!isLoaded ? <Loading/> : (
                  <HistoryTbody>
                    {historyDtos.map((historyDto) => (
                        <tr key={historyDto.day}>
                            <td>{historyDto.day}일</td>
                            <td>{historyDto.votes}표</td>
                            <td>{historyDto.point}점</td>
                            {historyDto.rank === 0 ? <td>-위</td> : <td>{historyDto.rank}위</td>}
                        </tr>
                    ))}
                </HistoryTbody>
                )}
                
            </HistoryTable>
        </>
    );
}
export default RankingHistoryList;

const HistoryTitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 0 32px 24px;
  background-color: #fff;
  
  & > h3 {
    font-weight: 600;
    font-size: 28px;
    line-height: 33px;
    letter-spacing: -0.7px;
    color: ${({theme}) => theme.colors.gray900};
  }
  & > h6 {
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.25px;
    color: ${({theme}) => theme.colors.gray700};
  }
`;

const HistoryTable = styled.table`
  width: 100%;
  height: 40px;
  background-color: #fff;
`;

const HistoryThead = styled.thead`
  height: 40px;
  & > tr {
    background-color: ${({theme}) => theme.colors.primary50};
    
    th {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      letter-spacing: -0.25px;
      color: ${({theme}) => theme.colors.primary700};
    }
    th:first-child { border-radius: 16px 0 0 16px }
    th:last-child { border-radius: 0 16px 16px 0 }
  }
`;

const HistoryTbody = styled.tbody`
  & > tr {
    height: 56px;
    text-align: center;
    
    td {
      font-size: 17px;
      line-height: 20px;
    }
    td:first-child {
      font-weight: 400;
      letter-spacing: -0.25px;
      color: ${({theme}) => theme.colors.gray700};
    }
    td:last-child {
      font-weight: 600;
      letter-spacing: -0.4px;
      color: ${({theme}) => theme.colors.primary500};
    }
    td:nth-child(2),td:nth-child(3) {
      font-weight: 500;
      letter-spacing: -0.25px;
      color: ${({theme}) => theme.colors.gray800};
    }
  }
`;