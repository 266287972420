import React, {forwardRef} from 'react';
import CalendarIcon from "../../assets/images/icon/Icon_Calendar.svg";
import arrowIcon from "../../assets/images/icon/Icon_Arrow.svg";
import styled from "styled-components";

const CustomInput = forwardRef(({sortType, value, onClick, inputType}, ref) => {
    return (
        <CustomInputWrapper>
            {sortType === 'monthly' ? (
            <>
                <CustomInputArrow className="custom-input-left-arrow">
                    <img src={arrowIcon} alt="leftArrow"/>
                </CustomInputArrow>
                <button className="example-custom-input" onClick={onClick} ref={ref}>
                    <span><img src={CalendarIcon} alt="CalendarIcon"/></span>
                    {value}
                </button>
                <CustomInputArrow className="custom-input-right-arrow">
                    <img src={arrowIcon} alt="RightArrow"/>
                </CustomInputArrow>
            </>
            ) : (
            <>
                <button className="example-custom-input" onClick={onClick} ref={ref}>
                    <span><img src={CalendarIcon} alt="CalendarIcon"/></span>
                    {value}
                </button>
                <CustomInputArrow className="custom-input-bottom-arrow">
                    <img src={arrowIcon} alt="BottomArrow"/>
                </CustomInputArrow>
            </>
            )}
        </CustomInputWrapper>
    );
});

export default CustomInput;

const CustomInputWrapper = styled.div`
  display: flex;
  align-items: center;
  height: inherit;
`;

const CustomInputArrow = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: inherit;

  & > img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: ${({theme}) => theme.colors.gray900};
  }

  &.custom-input-left-arrow img {
    transform: scale(-1);
  }
  
  &.custom-input-bottom-arrow img {
    transform: rotate(90deg);
  }
`;