import React from "react";
import styled from "styled-components";
import MonthlyBenefit from "../../assets/images/MonthlyBenefit.png";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Pagination, Navigation} from "swiper";

import 'swiper/css';

const PastBenefits = () => {
    return (
        <BannerWrapper>
            <Swiper
                style={{margin:"0 24px"}}
                pagination={{
                    type: "fraction",
                    clickable: true,
                }}
                loop={true}
                navigation={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination, Navigation]}
                spaceBetween={50}
                slidesPerView={1}
                onSwiper={(swiper) => swiper}>
                <SwiperSlide style={{backgroundColor:"#9485FA",borderRadius: "12px"}}><img src={MonthlyBenefit} alt="banner"/></SwiperSlide>
                <SwiperSlide style={{backgroundColor:"#9485FA",borderRadius: "12px"}}><img src={MonthlyBenefit} alt="banner"/></SwiperSlide>
            </Swiper>
        </BannerWrapper>
    )
}

export default PastBenefits;

const BannerWrapper = styled.nav`
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 120px;
  color: #fff;
  // background-color: ${({theme}) => theme.colors.secondary500};
  text-align: center;
  border-radius: 12px;
`;