export const getPastYear = () => {
    let date = new Date();
    let sel_month = -1;
    date.setMonth(date.getMonth() + sel_month );
    let pastYear    = date.getFullYear();
    return pastYear;
};

export const getPastMonth = () => {
    let date = new Date();
    let sel_month = -1;
    date.setMonth(date.getMonth() + sel_month );
    let pastMonth   = ('0' + (date.getMonth() +  1 )).slice(-2);
    return Number(pastMonth);
};

export const isCheckLessThanTen = (num) => num < 10 && num >= 0 ? String(`0${num}`) : num;