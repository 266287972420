import React from "react";
import DetailPageHeader from "../components/DetailPageHeader";
import BenefitsTab from "../components/Tab/BenefitsTab";

const Benefits = () => {
    return (
        <>
            <DetailPageHeader title="우승혜택"/>
            <BenefitsTab/>
        </>
    )
}

export default Benefits;