import React from "react";
import { useLocation } from "react-router-dom";
import DetailPageHeader from "../components/DetailPageHeader";
import RankingHistoryList from "../components/rank/RankingHistoryList";

const RankDetail = () => {
    const location = useLocation();
    console.log(location);

    return (
        <>
            {location.state.props === undefined ? (
                <>
                    <DetailPageHeader title={`${location.state.list.name} 일간누적순위`}/>
                    <RankingHistoryList data={location.state}/>
                </>
            ) : (
                <>
                    <DetailPageHeader title={`${location.state.props.list.name} 일간누적순위`}/>
                    <RankingHistoryList data={location.state.props}/>
                </>
            )
            }
        </>
    )
}

export default RankDetail;
