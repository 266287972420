import React from "react";
import {useNavigate} from "react-router-dom";
import styled from 'styled-components';
import TopNav from "../layout/TopNav";
import RankContent from "./RankContent";
import Footer from "../layout/Footer";
import GoToVote from "../rank/goToVote/GoToVote";
import {ReactComponent as HistoryIcon} from "../../assets/images/icon/Icon_History.svg";
import {ReactComponent as ArrowIcon} from "../../assets/images/icon/Icon_Arrow.svg";

const RankContainer = () => {
    const navigate = useNavigate();

    return (
        <ContainerWrapper>
            <TopNav/>
            <LastHistoryButton onClick={() => navigate("/rank/pastRanking")}>
                <span><HistoryIcon/></span>지난순위보기<ArrowIcon className="ArrowIcon"/>
            </LastHistoryButton>
            <RankContent/>
            {/* <GoToVote rankingCategory="순위" title="투표 하러가기"/> */}
            <Footer/>
        </ContainerWrapper>
    )
}
export default RankContainer;

const ContainerWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  min-height: calc(100vh - 64px);
  background-color: ${({theme}) => theme.colors.gray50};
  padding-bottom: 56px;
`;

const LastHistoryButton = styled.button`
  position: relative;
  display: flex;
  width: calc(100% - 24px);
  height: calc(100% - 32px);
  gap: 8px;
  background: #FFFFFF;
  box-shadow: 0 0 10px rgb(0 0 0 / 5%);
  border-radius: 16px;
  height: 56px;
  margin: 12px 16px;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.4px;
  color: ${({theme}) => theme.colors.secondary600};

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${({theme}) => theme.colors.secondary100};
  }

  & > .ArrowIcon {
    position: absolute;
    right: 16px;
  }
`;