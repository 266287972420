import React from "react";
import styled from 'styled-components';
import FanmaumLogo from "../../assets/images/FanmaumTextLogo.svg";

const Header = () => {
    return (
        <HeaderWrapper>
            <h1><img src={FanmaumLogo} alt="FanmaumLogo"/></h1>
        </HeaderWrapper>
    )
}

export default Header;

const HeaderWrapper = styled.header`
  position: sticky;
  width: 100%;
  max-width: 768px;
  top: 0;
  display: flex;
  flex-direction: column;
  z-index: 9;
  height: 64px;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  h1 {
    width: 120px;
    height: 40px;
  }

  img {
    width: inherit;
    height: inherit;
  }
`;