import React from "react";
import styled from "styled-components";
import Top1 from "../../assets/images/icon/Icon_MonthlyTop1.svg";
import Top2 from "../../assets/images/icon/Icon_MonthlyTop2.svg";
import Top3 from "../../assets/images/icon/Icon_MonthlyTop3.svg";

const RankingImage = ({list}) => {
    return (
        <MonthlyTopImage>
            {list.rank === 1
                ? (<img src={Top1} alt="MonthlyTop"/>)
                : (list.rank === 2
                    ? (<img src={Top2} alt="MonthlyTop"/>)
                    : (<img src={Top3} alt="MonthlyTop"/>)
                )}
        </MonthlyTopImage>
    )
}

export default RankingImage;

const MonthlyTopImage = styled.span`
  width: 40px;
  height: 40px;
  
  & > img {
    width: 100%;
    height: 100%;
  }
`;