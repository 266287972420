import React, { useState, useEffect } from "react";
import styled from "styled-components";
import BtnChip from "../button/BtnChip";
import RankTab from "../Tab/RankTab";
import {isCheckLessThanTen} from "../../utils/DateUtil";

const RankContent = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const updateScroll = () => {
        setScrollPosition(window.scrollY);
        hideChipButton();
    }

    const hideChipButton = () => {
        let chipButton = document.querySelector(".chipButton");
        if(scrollPosition < 10) chipButton.classList.add("hideChipButton");
    }

    useEffect(()=> {
        window.addEventListener('scroll', updateScroll);
        return () => window.removeEventListener("scroll", updateScroll);
    },[]);

    // 투표마감까지 남은 시간
    let now = new Date();
    let year = now.getFullYear();
    let month = now.getMonth() + 1;
    let lastDay = new Date(year, month, 0, 23,59);
    let setDate = new Date(lastDay);
    let todayDate = now.getDate();

    let distance = setDate.getTime() - now.getTime();

    let days = Math.floor(distance/(1000*60*60*24));
    let hours = Math.floor((distance % (1000*60*60*24))/(1000*60*60));
    let minutes = Math.floor((distance % (1000*60*60))/(1000*60));

    let [day, setDay] = useState(days);
    let [hour, setHour] = useState(hours);
    let [minute, setMinute] = useState(minutes);

    useEffect(() => {
        const countdown = setInterval(() => {
            if(parseInt(minute) === 0){
                if(parseInt(hour) === 0){
                    if(parseInt(day) === 0){
                        clearInterval(countdown);
                    }else {
                        setDay(parseInt(day)-1)
                        setHour(23)
                        setMinute(59)
                    }
                }else {
                    setHour(parseInt(hour)-1)
                    setMinute(59)
                }
            }else {
                setMinute(parseInt(minute)-1)
            }
        }, 1000);
        return () => clearInterval(countdown);
    },[day,hour,minute]);

    return (
        <RankingSection>
            <RankingHeadLine className={scrollPosition < 200 ? "" : "scrolledHeadLine"}>
                {todayDate === 1 ?
                    (
                        <>
                            <h2>{month-1}월 현재 순위</h2>
                            <p>{month}월 순위는 <span>{todayDate + 1}일</span>부터 공개됩니다</p>
                        </>
                    )
                    : (
                        <>
                            <h2>{month}월 현재 순위</h2>
                            {day > 0 ? (
                                <p>투표마감까지 <span>{day}일</span> 남았습니다.</p>
                                ) : (
                                <p>투표마감까지 <span>{isCheckLessThanTen(hour)}:{isCheckLessThanTen(minute)}</span> 남았습니다.</p>
                                )
                            }
                        </>
                    )
                }
            </RankingHeadLine>
            <RankTab/>
            <BtnChip title={"화면을 내려보세요"} />
        </RankingSection>
    )
}
export default RankContent;

const RankingSection = styled.section`
  background-color: #fff;
`;

const RankingHeadLine = styled.div`
  position: sticky;
  top: 64px;
  display: flex;
  height: 94px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  background-color: #fff;
  z-index: 9;
  
  &.scrolledHeadLine {
      border-bottom: 1px solid #E6EAEB;
  }
  
  h2 {
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.3px;
    color: ${({theme})=> theme.colors.gray900};
  }
  p {
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.25px;
    color: ${({theme})=> theme.colors.gray700};
  }
  span {
    color: ${({theme}) => theme.colors.primary500};
  }
`;